<template>
    <div ref="wrapper" id="notif-wrapper">
      <div ref="notif" id="notif-container">
        <p>
          {{ $t('contact.success') }}
        </p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Success',
  data: function () {
    return {

    }
  },
  created () {
    let leave = setTimeout(() => {
      this.$refs.wrapper.style.opacity = '1'
      this.$refs.notif.style.opacity = '1'
      this.$refs.wrapper.classList.add('leave-wrapper')
      this.$refs.notif.classList.add('leave')
      clearTimeout(leave)
    }, 3000)
    let done = setTimeout(() => {
      clearTimeout(done)
      this.$emit('done')
    }, 3500)
  }
}
</script>

<style lang="scss" scoped>
#notif-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn .2s forwards;
  background: rgba(0, 0, 0, .4);
  opacity: 0;
}

.leave-wrapper {
  animation: fadeOut .2s .3s forwards !important;
}

.leave {
  animation: leave .3s .2s forwards !important;
}

#notif-container {
  width: 400px;
  max-width: 95vw;
  border-radius: .375rem;
  background: white;
  padding: 2rem 3rem;
  animation: enter .7s .2s forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes enter {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes leave {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(50%);
    opacity: 0;
  }
}
</style>
