<template>
  <div>
    <div id="content">
      <h1>
        {{ $t('about.label') }}
      </h1>
      <color-line id="color-line" :start="'dodgerblue'" :stop="'blue'" />
      <p id="about-desc">
        {{ $t('about.desc.top') }}
        <br/>
        <br/>
        {{ $t('about.desc.bottom') }}
      </p>
    </div>
    <div id="img-container" data-aos="zoom-out" data-aos-anchor-placement="top-center">
      <img loading="lazy" :src="require(`../../assets/images/fav.jpg`)" alt="">
    </div>
  </div>
</template>
<script>
import ColorLine from '@/components/indicator/color-line'

export default {
  name: 'About',
  data: function () {
    return {
    }
  },
  props: {

  },
  components: {
    'color-line': ColorLine
  },
  created () {

  },
  methods: {

  }
}
</script>
<style lang="scss">
#about {
  padding: 0 5rem;
  padding-top: 5rem;
  display: flex;
  align-items: center;
  max-width: 1600px;
  margin: auto;
  #content {
    text-align: left;
    padding: 0px 2rem;
    flex: 1;
    #about-desc {
      max-width: 1050px;
      margin: 2rem 0;
      font-size: 1rem;
      line-height: 2rem;
      color: #4A5568;
    }
    #sign-about {
      margin: auto;
      height: 70px;
      margin-top: 1rem;
    }
  }
  #img-container {
    height: 900px;
    overflow: hidden;
    img {
      height: 115%;
    }
  }
}

@media only screen and (max-width : 1500px) {
  #img-container {
    display: none;
  }
  #about {
    padding: 5rem 1rem 12rem 1rem !important;
    #content {
      text-align: center;
      #color-line {
        margin: auto;
      }
      #about-desc {
        margin: 2rem auto;
      }
    }
  }
}
</style>
