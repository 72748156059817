<template>
    <svg viewBox="-21 -21 682.66669 682.66669" xmlns="http://www.w3.org/2000/svg"><path d="m166.148438 640h-165.460938v-445.839844h165.460938zm0 0"/><path d="m640 640h-162.101562l.070312-197.234375c.679688-32.015625-12.242188-58.421875-34.5625-70.640625-15.324219-8.394531-32.960938-8.417969-49.507812-.332031l-1.429688 268.207031h-169.648438v-445.839844h169.75v18.121094c52.738282-21.382812 108.664063-18.296875 157.265626 9.5 54.773437 31.335938 90.164062 89.265625 90.164062 147.585938zm0 0"/><path d="m83.078125 166.320312c-45.8125 0-83.078125-37.304687-83.078125-83.160156 0-45.855468 37.265625-83.160156 83.078125-83.160156 45.804687 0 83.070313 37.304688 83.070313 83.160156 0 45.855469-37.265626 83.160156-83.070313 83.160156zm0 0"/></svg>
</template>

<script>
export default {
  name: 'Linkedin'
}
</script>

<style lang="scss" scoped>

</style>
