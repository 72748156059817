<template>
    <div>
      <color-line style="margin: auto;" start="dodgerblue" stop="blue" />
      <h1>
        {{ $t('project.label') }}
      </h1>
      <swiper v-if="projects.length" class="swiper" :loop="true" :effect="coverflow" navigation :coverflow-effect="{rotate: 0,stretch: 0,depth: 0,modifier: 1,slideShadows: false,}" @swiper="onSwiper" :grab-cursor="true" :speed="700" @slideChange="onSlideChange" :slides-per-view="'auto'" :space-between="0" :centered-slides="true">
        <swiper-slide v-for="p in projects" :key="p.name">
          <div class="project-item">
              <div class="thumb">
                <img loading="lazy" :src="require(`../../assets/images/projects/${p.key}.jpg`)" alt="">
              </div>
              <div class="project-content">
                <p class="project-name" style="color: black;">
                  <strong>
                    {{ p.name }}
                  </strong>
                </p>
                <p class="period">
                  {{ $t(`project.period.${p.key}`) }}
                </p>
                <div class="project-tabs" style="margin-bottom: 1rem; width: 100%; height: calc(560px - 180px - 3rem); position: relative; overflow: hidden">
                  <transition name="fade">
                    <div v-if="!p.tab" style="position: absolute; height: 100%; width: 100%;">
                      <p class="desc">
                        {{ $t(`project.desc.${p.key}`) }}
                      </p>
                      <div class="technos-wrapper" style="position: absolute; bottom: 0; width: 100%;">
                        <div class="technos">
                          <p v-for="t in p.techno" :key="t">
                            {{ t }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%;">
                      <div class="objective-item" v-for="(o, i) in p.obj_l" :key="i">
                        <p style="flex: 1;">
                          {{ $t(`project.objs.${p.key}.${i}`) }}
                        </p>
                        <base-icon :style="`color: ${o ? 'green' : 'blue'}; height: 18px; margin-left: 1rem;`" :name="`${o ? 'validate' : 'still'}`" />
                      </div>
                    </div>
                  </transition>
                </div>
                <button class="toggle-tab" @click="p.tab = !p.tab">
                  <div class="toggle-hover"></div>
                  {{ !p.tab ? $t('project.obj') : $t('project.descri') }}
                </button>
                <a class="link-project" target="_blank" :href="p.url">
                  <BaseIcon name="arrow" />
                </a>
              </div>
              <div class="overlay" :style="{backgroundImage: 'url(' + require(`../../assets/images/projects/${p.key}-bg.jpg`)}">
                <div class="overlay-child">
                </div>
              </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
</template>

<script>
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import ColorLine from '@/components/indicator/color-line'

SwiperCore.use([Navigation, EffectCoverflow])
export default {
  name: 'Portfolio',
  components: {
    Swiper,
    SwiperSlide,
    'color-line': ColorLine
  },
  data: function () {
    return {
      projects: [
        {
          name: 'No more lag',
          techno: [
            'reactjs',
            'nodejs',
            'expressjs',
            'mongodb',
            'jwt'
          ],
          desc: '',
          type: '',
          key: 'nomorelag',
          url: 'https://competent-hoover-75abd2.netlify.app/#/sign',
          obj_l: [true, true, true, false, false, false],
          tab: false
        },
        {
          name: 'G - Talents',
          techno: [
            'wordpress',
            'swiper',
            'php'
          ],
          desc: '',
          type: '',
          key: 'gtalents',
          url: 'https://www.g-talents-recrutement.com/',
          obj_l: [true, true, true],
          tab: false
        },
        {
          name: 'Clovis échecs',
          techno: [
            'reactjs',
            'nodejs',
            'expressjs',
            'jwt',
            'mongodb',
            'cloudinary'
          ],
          desc: '',
          type: '',
          key: 'clovisechecs',
          url: 'https://www.clovisechecs.ch/',
          obj_l: [true, true, true, true],
          tab: false
        },
        {
          name: 'Sim & Get',
          techno: [
            'reactjs',
            'php'
          ],
          desc: '',
          type: '',
          key: 'simget',
          url: 'https://sim-and-cure.com/language/fr/accueil',
          obj_l: [true, true, true, true],
          tab: false
        },
        {
          name: 'No clope',
          techno: [
            'react-native',
            'reactjs',
            'chartjs',
            'jwt'
          ],
          desc: '',
          type: '',
          key: 'noclope',
          url: 'https://noclope.com/',
          obj_l: [true, true, false, false],
          tab: false
        },
        {
          name: 'Il Vesuvio',
          techno: [
            'wordpress',
            'facebook-ads'
          ],
          desc: '',
          type: '',
          key: 'ilvesuvio',
          url: 'https://ilvesuvio-ristorante.ch/',
          obj_l: [true, true, true],
          tab: false
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.objective-item {
  display: flex;
  align-items: center;
  padding: .35rem 0;
  p {
    margin: 0;
    font-size: .9rem;
  }
}

.project-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
  object-fit: cover;
  transition: .3s all ease-in-out;
}
.swiper-button-next, .swiper-button-prev {
  color: black;
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.3);
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 1.3rem !important;
}

@media only screen and (max-width : 800px) {
  .swiper-button-next, .swiper-button-prev {
    margin-left: 0rem;
    margin-right: 0rem;
    height: 40px;
    width: 40px;
  }
  #portfolio {
    h1 {
      margin-bottom: 2rem !important;
    }
  }
}

.project-content {
  transition: .5s all ease-in-out;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: white;
  max-width: calc(85vw - 4rem);
  width: calc(360px - 4rem);
  display: flex;
  flex-direction: column;
  padding: 0px 2rem;
  padding-bottom: .75rem;
  border-radius: 0px 0px .75rem .75rem;
  text-align: left;
  height: 60px;
  z-index: 10;
  .project-tabs, .toggle-tab {
    opacity: 0;
    transition: all .3s ease-in-out;
  }
  .project-name {
    font-size: 1.3rem;
    margin: 0;
    transition: .4s margin ease-in-out;
  }
  .period {
    color: blue;
    font-size: .9rem;
    margin: 5px 0px;
    font-weight: 600;
  }
  .desc {
    font-size: .9rem;
  }
  .technos-wrapper {
    flex: 1;
    display: flex;
    align-items: flex-end;
    .technos {
      display: flex;
      flex-wrap: wrap;
      * {
        margin: .25rem 0px;
        margin-right: 5px;
        background: lightgray;
        border-radius: .5rem;
        padding: .25rem .75rem;
        font-size: .8rem;
        font-weight: 600;
      }
    }
  }
  button {
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .link-project {
    position: absolute;
    bottom: 0px;
    right: -80px;
    color: black;
    width: fit-content;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.20);
    border-radius: .75rem 0 0 0;
    background: white;
    padding: .5rem .75rem;
    transition: all ease-in-out .4s;
    svg {
      margin: 0 .5rem;
      margin-top: .25rem;
      height: 2rem;
    }
  }
  .link-project:hover > svg > path {
    fill: blue;
  }
  .toggle-tab {
    border-radius: .75rem;
    padding: .75rem 1.25rem;
    flex: 1;
    max-height: 3rem;
    color: white;
    background: linear-gradient(to right, dodgerblue, blue) !important;
    transition: .4s all ease-in-out;
    position: relative;
    .toggle-hover {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to top, rgba(255,255,255,.7), rgba(255,255,255,.1));
      border-radius: .75rem;
      opacity: 0;
      transition: .2s all ease-in-out;
    }
  }
  .toggle-tab:hover {
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.20);
    .toggle-hover {
      opacity: .3;
    }
  }
}

#portfolio {
  display: flex;
  flex-wrap: wrap;
  padding: 6rem 0px;
  h1 {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

.project-item {
  height: 100%;
  border-radius: .75rem;
  overflow: hidden;
  width: 310px;
  max-width: 85vw;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.090);
  margin: auto;
  transition: width ease-in-out .4s,
  height ease-in-out .4s,
  box-shadow ease-in-out .3s .2s !important;
  position: relative;
  .overlay {
    transition: .2s .2s all ease-in-out;
    height: calc(100% - 84px);
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .overlay-child {
      height: 100%;
      width: 100%;
      background: linear-gradient(to top, black, transparent);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      p {
        color: white;
        margin-bottom: 2rem;
        font-size: 1.2rem;
      }
    }
  }
}

.swiper {
  height: 625px;
  width: 100%;
  .swiper-slide {
    transition: all ease-in-out .4s;
    text-align: center;
    margin: auto 0px;
    height: 450px;
    width: 360px;
    max-width: 90vw;
    border-radius: .75rem !important;
    padding: 0px .5rem;
    .thumb {
      transition: all ease-in-out .5s;
      background: white;
      position: absolute;
      z-index: 20;
      top: -180px;
      left: 0px;
      width: 360px;
      max-width: 85vw;
      border-radius: .75rem .75rem 0px 0px;
      img {
        object-fit: cover;
        margin: auto;
        width: 90%;
        max-height: 140px;
        padding: .5rem 0px;
      }
    }
  }
  .swiper-slide-active {
    height: 555px;
    .project-item {
      width: 360px;
      box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.090);
      background-image: white;
    }
    .project-content {
      height: calc(560px - 180px - 3rem);
      padding-top: 180px;
      border-radius: .75rem .75rem;
      padding-bottom: 3rem;
      border: none;
      .project-tabs, .toggle-tab {
        opacity: 1;
      }
      .project-name {
        font-size: 1.3rem;
      }
      .link-project {
        right: 0px;
        transition: all ease-in-out .4s .4s;
      }
    }
    .thumb {
      top: 0px;
      transition: all ease-in-out .5s .3s;
    }
    .overlay {
      transition: all ease-in-out .2s .4s;
      width: 95%;
      margin-left: 2.5%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
