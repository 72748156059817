<template>
  <div id="cover-container">
    <div id="cover-content">
      <h1>
        Dév. <span style="color: blue;">Web</span><br>Designer
      </h1>
      <p>
        {{ $t('cover.content') }}
      </p>
      <button ref="quote" class="btn-dark dark shadow-xl" style="margin-top: 2rem; margin-bottom: 2rem;" v-scroll-to="{ el: '#contact' }">
        {{ $t('header.quote') }}
      </button>
    </div>
    <div class="img-container">
      <img :class="`${loaded ? 'appear' : ''}`" :src="require('../../assets/images/cover.jpg')" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Cover',
  data: function () {
    return {
      loaded: false
    }
  },
  props: {

  },
  created () {
    window.addEventListener('load', this.loadedEvt)
  },
  unmounted () {
    window.removeEventListener('load', this.loadedEvt)
  },
  methods: {
    loadedEvt () {
      let temp = setTimeout(() => {
        this.loaded = true
        clearTimeout(temp)
      }, 500)
    }
  }
}
</script>
<style lang="scss">
  @import "../../scss/_variables.scss";

  #cover-container {
    height: 100vh;
    max-height: 100vh;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-container {
      height: 100%;
      overflow: hidden;
      max-height: fit-content;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex: 1;
      img {
        max-width: 100%;
      }
    }
  }
  #cover-content {
    max-width: 500px;
    text-align: left;
    padding: 50px 0;
    padding-left: 180px;
    h1 {
      font-size: 4.5rem;
      line-height: 5.5rem;
      font-weight: 600;
    }
  }

  @keyframes fade {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }
  @-moz-keyframes fade {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }

  @media only screen and (max-width : 1024px) {
    #cover-container {
      .img-container {
        display: none;
      }
      img {
        max-width: 100% !important;
        max-height: 100%;
      }
      #cover-content {
        margin: 0 !important;
        padding: 3rem !important;
        h1 {
          font-size: 3.5rem !important;
          line-height: 4rem !important;
        }
      }
    }
  }

  @media only screen and (max-width : 1400px) {
    #cover-content {
      padding-left: 90px !important;
    }
  }

  .img-container {
    img {
      transform: translateY(100%);
    }
    .appear {
      animation: appear_img .3s forwards;
    }
  }

  @keyframes appear_img {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
</style>
