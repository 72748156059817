<template>
  <label class="label" :for="name">{{ title }}</label>
  <input
    :id="name"
    v-bind="$attrs"
    class="base-input"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
  />
</template>
<script>
export default {
  name: 'BaseInput',
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
