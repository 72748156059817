<template>
    <div id="notif-wrapper">
      <div id="notif-container">
        <p>
          {{ $t('contact.error') }}
        </p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Error',
  data: function () {
    return {
      animation: ''
    }
  }
}
</script>

<style lang="scss" scoped>
#notif-wrapper {
  position: fixed;
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn .2s forwards;
  background: rgba(0, 0, 0, .4);
}

#notif-container {
  width: 400px;
  max-width: 95vw;
  border-radius: .375rem;
  background: white;
  padding: 2rem 3rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
