<template>
    <div>
      <div class="wrapper">
        <div class="content" data-aos="fade-up">
          <h1>
            {{ $t('services.custom.label') }}
          </h1>
          <color-line :start="'dodgerblue'" :stop="'blue'" />
          <p>
            {{ $t('services.custom.desc.0') }}
            <br />
            <br />
            {{ $t('services.custom.desc.1') }}
          </p>
        </div>
        <div class="mockup" data-aos="zoom-out" data-aos-anchor-placement="top-center">
          <img loading="lazy" :src="require('../../assets/images/custom.png')" alt="">
        </div>
      </div>
      <div class="wrapper reverse-wrapper">
        <div class="mockup" data-aos="zoom-out" data-aos-anchor-placement="top-center">
          <img loading="lazy" :src="require('../../assets/images/showcase.png')" alt="">
        </div>
        <div class="content" data-aos="fade-up">
          <h1>
            {{ $t('services.vitrine.label') }}
          </h1>
          <color-line :start="'dodgerblue'" :stop="'blue'" />
          <p>
            {{ $t('services.vitrine.desc.0') }}
            <br />
            <br />
            {{ $t('services.vitrine.desc.1') }}
          </p>
        </div>
      </div>
      <div class="wrapper mobile-wrapper">
        <div class="content mobile-content" data-aos="fade-up">
          <h1>
            {{ $t('services.vitrine.label') }}
          </h1>
          <color-line :start="'dodgerblue'" :stop="'blue'" />
          <p>
            {{ $t('services.vitrine.desc.0') }}
            <br />
            <br />
            {{ $t('services.vitrine.desc.1') }}
          </p>
        </div>
        <div class="mockup mobile-content" data-aos="zoom-out" data-aos-anchor-placement="top-center">
          <img loading="lazy" :src="require('../../assets/images/showcase.png')" alt="">
        </div>
      </div>
      <div class="wrapper">
        <div class="content" data-aos="fade-up">
          <h1>
            {{ $t('services.identity.label') }}
          </h1>
          <color-line :start="'dodgerblue'" :stop="'blue'" />
          <p>
            {{ $t('services.identity.desc.0') }}
            <br />
            <br />
            {{ $t('services.identity.desc.1') }}
            <br />
            <br />
            {{ $t('services.identity.desc.2') }}
          </p>
        </div>
        <div class="mockup" data-aos="zoom-out" data-aos-anchor-placement="top-center">
          <img loading="lazy" :src="require('../../assets/images/identity.png')" alt="">
        </div>
      </div>
    </div>
</template>

<script>
import ColorLine from '@/components/indicator/color-line'
export default {
  name: 'Services',
  components: {
    'color-line': ColorLine
  }
}
</script>

<style lang="scss">
#services {
  padding: 4rem;
  p {
    margin-top: 2rem;
  }
  .wrapper {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 10rem;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: .75rem;
    overflow: hidden;
    .content {
      flex: 1;
      margin: auto;
      padding: 0px 4rem;
      * {
        max-width: 700px;
      }
    }
    .mockup {
      flex: 1;
      min-width: 55%;
      height: 600px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    background: white;
  }
}

.mobile-wrapper {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
}

@media only screen and (max-width : 1024px) {
  #services {
    padding: 2rem 0;
  }
  .wrapper {
    flex-direction: column !important;
    box-shadow: none !important;
  }
  .content {
    padding: 4rem 1.5rem 1.5rem 1.5rem !important;
    p {
      margin-bottom: 0;
    }
    h1 {
      margin-top: 0;
    }
  }
  .mockup {
    height: auto !important;
  }
  .reverse-wrapper {
    display: none !important;
  }
  .mobile-wrapper {
    position: relative !important;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
}
</style>
