<template>
  <div id="loading-container" :class="loaded ? 'load-done' : ''">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#0000ff" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
      </path>
    </svg>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    loaded: Boolean
  },
  mounted () {

  }
}
</script>
<style lang="scss">
#loading-container {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
  background-color: white;
}

.load-done {
  animation: load .8s ease-in-out forwards;
}

@keyframes load {
  0% {

  }
  99% {
    opacity: 0;
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-moz-keyframes load {
  0% {

  }
  99% {
    opacity: 0;
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

</style>
