<template>
  <div class="color-line" :style="{background: 'linear-gradient(to right,'+ start + ',' + stop + ')'}"></div>
</template>
<script>
export default {
  name: '',
  data: function () {
    return {

    }
  },
  props: {
    start: String,
    stop: String
  },
  created () {

  },
  methods: {

  }
}
</script>
<style lang="scss">
.color-line {
  height: 2px;
  width: 100px;
  max-width: 100px !important;
}
</style>
