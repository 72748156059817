<template>
  <div>
    <Error @done="() => { error = false; success = false }" v-if="error" />
    <Success @done="() => { error = false; success = false }" v-if="success" />
    <div id="wrapper-contact" data-aos="fade-right" style="text-align: left;">
      <h1>
        {{ $t('contact.label') }}
      </h1>
      <color-line style="margin-bottom: 2rem;" :start="'dodgerblue'" :stop="'blue'" />
      <div id="form-contact">
        <div class="inline-input left-input">
          <BaseInput
            :title="$t('contact.name')"
            v-model="name"
            type="text"
            name="name"
            required
          />
        </div>
        <div class="inline-input right-input">
          <BaseInput
            :title="$t('contact.email')"
            v-model="email"
            type="text"
            name="email"
            required
          />
        </div>
        <p class="label">
          {{ $t('contact.lservices') }}
        </p>
        <div class="wrapper-services">
          <button :class="`service-toggle ${selected.indexOf(s) >= 0 ? 'service-active' : ''}`" v-for="s in services" :key="s" @click="toggleService(s)">
            <div></div>
            <p>
              {{ $t(`contact.services.${s}`) }}
            </p>
          </button>
        </div>
        <label class="label" for="message">
          {{ $t('contact.message') }}
        </label>
        <textarea
          required
          v-model="message"
          id="message"
          cols="30"
          rows="10"
        >
        </textarea>
        <div id="check-container" @click="() => verif = !verif">
          <input type="checkbox" name="verif" id="" v-model="verif">
          <p ref="robot" :style="{color: verif ? 'blue' : '', opacity: verif ? '1' : '.4'}">
            {{ $t('contact.robot') }}
          </p>
        </div>
        <div class="wrapper-action">
          <button id="btn-send" @click="onSubmit" :class="`${!(completed === 4 && verif) ? 'btn-disabled' : ''}`" :disabled="!(completed === 4 && verif)">
            {{ !this.loading ? $t('contact.send') : $t('contact.loading') }}
          </button>
          <p :style="{color: completed == 4 ? 'blue' : '', opacity: completed == 4 ? '1' : ''}">
            {{ completed }} / 4 {{ $t('contact.remaining') }}
          </p>
        </div>
      </div>
    </div>
    <div id="img-contact" data-aos="fade">
      <img :src="require('../../assets/images/contact.jpg')" alt="">
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import ColorLine from '@/components/indicator/color-line'
import Success from '@/components/notification/success'
import Error from '@/components/notification/error'
import BaseInput from '@/components/form/baseInput'
import emailjs from 'emailjs-com'

export default {
  name: 'Contact',
  setup () {
    const loading = ref(false)
    const success = ref(false)
    const error = ref(false)
    const form = ref(null)
    const name = ref('')
    const email = ref('')
    const message = ref('')
    const verif = ref(false)
    const robot = ref(null)
    const services = ref([
      'showcase', 'specs', 'webdesign', 'graphic', 'logo', 'marketing'
    ])
    const selected = ref([])
    const completed = computed(() => {
      return (name.value !== '' ? 1 : 0) + (email.value !== '' ? 1 : 0) + (selected.value.length > 0 ? 1 : 0) + (message.value !== '' ? 1 : 0)
    })

    onMounted(() => {
      emailjs.init('user_CspWWv0zxwHG3NUBlySQa')
    })

    function toggleService (s) {
      selected.value.indexOf(s) >= 0 ? selected.value = selected.value.filter(cs => cs !== s) : selected.value.push(s)
    }
    function onSubmit () {
      loading.value = true
      const mail = {
        name: name.value,
        message: message.value,
        email: email.value,
        services: services.value.join(' | ')
      }
      emailjs.send('gmailportfolio', 'template_j704u0y', mail)
        .then(() => {
          loading.value = false
          success.value = true
          name.value = ''
          message.value = ''
          email.value = ''
          services.value = []
        }, () => {
          loading.value = false
          success.value = false
          error.value = true
        })
      verif.value = false
      robot.value.classList.remove('robot-error')
    }

    return {
      loading,
      success,
      error,
      form,
      name,
      email,
      message,
      verif,
      robot,
      services,
      selected,
      completed,
      toggleService,
      onSubmit
    }
  },
  components: {
    'color-line': ColorLine,
    Success,
    Error,
    BaseInput
  }
}
</script>
<style lang="scss">
.robot-error {
  opacity: 1 !important;
  color: red !important;
}
#notif {
  height: 0px;
  transition: .4s all ease-in-out;
  overflow: none;
  opacity: 0;
  margin-top: 0px;
  margin-bottom: 1rem;
}
#contact {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  #form-contact {
    display: flex;
    flex-wrap: wrap;
    * {
      width: 100%;
    }
    .inline-input {
      flex: 1;
    }

    .left-input {
      margin-right: 1rem;
    }

    .right-input {
      margin-left: 1rem;
    }
  }
  #wrapper-contact {
    padding: 5rem 80px;
    * {
      width: 100%;
    }
  }
  #contact-desc {
    margin: 2rem 0px;
    font-size: 1.1rem;
    line-height: 2rem;
    text-align: left;
    color: #4A5568;
  }
  .label {
    font-size: 1.1rem;
    color: #2D3748;
  }
  input, textarea {
    font-family: 'Poppins';
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid lightgray;
    margin-bottom: 2rem;
    padding: .5rem 0px;
  }
  input:focus, textarea:focus {
    outline: none;
    border-color: dodgerblue;
  }
  #check-container {
    display: flex;
    align-items: center;
    margin-top: -1.5rem;
    margin-bottom: .75rem;
    p {
      transition: all ease-in-out .4s;
    }
    input {
      margin: 0px;
      margin-right: .75rem;
      width: fit-content;
    }
  }
}

.wrapper-services {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.service-toggle {
  background: rgb(236, 236, 236);
  padding: .5rem 2rem;
  border-radius: .75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  text-align: left;
  transition: .4s all ease-in-out;
  * {
    cursor: pointer;
  }
  p {
    font-weight: 500;
    font-size: .9rem;
    transition: .4s all ease-in-out;
  }
  div {
    height: 15px;
    width: 18.5px !important;
    border: 3px solid white;
    background: white;
    transition: .4s all ease-in-out;
    border-radius: 50%;
    margin-right: 1rem;
  }
}

.service-toggle:hover {
  background: rgb(190, 190, 190);
}

.service-active {
  background: dodgerblue !important;
  p {
    color: white;
  }
  div {
    background: rgb(91, 212, 91) !important;
  }
}

.wrapper-action {
  display: flex;
  align-items: center;
  p {
    text-align: right;
    transition: .3s all ease-in-out;
    opacity: .5;
  }
}

#btn-send {
  cursor: pointer;
  padding: 13px 52px !important;
  width: fit-content !important;
  font-size: 18px;
  border-radius: 30px;
  border: none;
  color: white;
  transition: .3s all ease;
  background: blue;
  background-position: left;
}

.btn-disabled {
  background: lightgray !important;
  color: gray !important;
}

#btn-send:hover {
  border-radius: 8px;
}

#img-contact {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  img {
    max-height: 95%;
  }
}

@media only screen and (max-width : 1550px) {
  #form-contact {
    margin: auto;
  }

  #img-contact {
    display: none;
  }
}

@media only screen and (max-width : 800px) {
  #wrapper-contact {
    padding: 5rem 2rem !important;
  }
  .wrapper-services {
    grid-template-columns: 1fr 1fr !important;
  }
  .inline-input {
    margin: 0 !important;
    flex: none !important;
  }
  #img-contact {
    img {
      max-width: 100vw !important;
    }
  }
}

@media only screen and (max-width : 500px) {
  .wrapper-services {
    grid-template-columns: 1fr !important;
  }
}
</style>
