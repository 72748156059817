<template>
  <div>
    <button @click="this.onClick" type="button">
      <div id="top">
        <div></div>
      </div>
      <div id="bottom"></div>
      <div id="hide"></div>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    onClick: { type: Function, default: () => { } }
  }
}
</script>
<style lang="scss" scoped>
button {
  height: 100%;
  display: flex;
  border: none;
  background: transparent;
  align-items: center;
  transition: .3s all ease-in-out;
  * {
    transition: .3s all ease-in-out;
    height: 35px;
    width: 7px;
    background: black;
    margin-right: 3px;
    transform: rotate(50deg);
    background: linear-gradient(to top, dodgerblue, blue);
  }
  #hide {
    opacity: 1;
    transform: translateY(0px) translateX(-10px) rotate(50deg);
  }
  #top {
    position: relative;
    * {
      position: absolute;
      top: 0;
      left: 0;
      background: black !important;
      transform: none !important;
      height: 100%;
      width: 100%;
    }
  }
}

button:hover {
  padding: 0 8px;
  * {
    width: 4px;
    height: 28px;
  }
  #hide {
    opacity: 1;
  }
  #top{
    transform: translateY(-10px) rotate(90deg);
    * {
      opacity: 0;
    }
  }
  #bottom {
    transform: translateY(0px) translateX(-7px) rotate(90deg);
  }
  #hide {
    transform: translateY(10px) translateX(-14px) rotate(90deg);
  }
}
</style>
