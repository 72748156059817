<template>
  <div ref="cursor" class="cursor"></div>
  <Loading :loaded="loaded" @load-content="loaded = true"></Loading>
  <Main :loaded="loaded" />
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Loading from "@/components/animation/loading.vue";
import Main from "./components/container/main.vue";

export default {
  name: "App",
  data: function () {
    return {
      ip: null,
      loaded: false,
      x: 0,
      y: 0,
      mouseXY: {},
    };
  },
  components: {
    Loading,
    Main,
  },
  created() {
    AOS.init();
    window.addEventListener("blur", () => {
      document.title = "Hey ✌ ⌛";
    })
    window.addEventListener("focus", () => {
      document.title = "Loïc Miralles ✌️";
    })
    window.addEventListener("load", this.handleLoad)
    window.addEventListener("click", this.handleClick)
    window.addEventListener("mousemove", this.handleMouseMove)
  },
  methods: {
    handleLoad() {
      setTimeout(() => {
        this.loaded = true;
      }, 300);
    },
    handleClick() {
      this.$refs.cursor.classList.add("expand");
      setTimeout(() => {
        this.$refs.cursor.classList.remove("expand");
      }, 500);
    },
    handleMouseMove(e) {
      this.$refs.cursor.setAttribute(
        "style",
        `top:${e.pageY - 10}px; left:${e.pageX - 10}px;`
      );
    },
  },
};
</script>

<style lang="scss">
@import "./scss/_global.scss";

#app {
  position: relative;
}

.cursor {
  z-index: 9999;
  width: 25px;
  height: 25px;
  border: 1px solid blue;
  border-radius: 50%;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursorAnim 0.8s infinite alternate;
  pointer-events: none;
}

.cursor::after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  border: 6px solid blue;
  border-radius: 50%;
  opacity: 1;
  top: -6px;
  left: -6px;
  animation: cursorAnim2 0.8s infinite alternate;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

.expand {
  animation: cursorAnim3 0.5s forwards;
  border: 1px solid blue;
}

@media only screen and (max-width : 1280px) {
  .cursor {
    display: none;
  }
}
</style>


