export const messages = {
  en: {
    header: {
      quote: 'Request a quote'
    },
    cover: {
      content: `As a 23 year old freelance developer and web designer, I develop a unique digital identity and web experience for each of my clients.`
    },
    menu: {
      services: 'Services',
      portfolio: 'Portfolio',
      about: 'About',
      contact: 'Contact',
      close: 'Close'
    },
    project: {
      label: 'My work',
      desc: {
        nomorelag: `No more lag is a platform to gather information and tools on topics such as hardware esport or content creation.`,
        gtalents: `G-Talents is a headhunting and talent scouting firm from Salesforce Lyon.`,
        simget: `Sim & Get is a French startup specialized in the fusion between modern medicine and artificial intelligence.`,
        clovisechecs: `Clovis Chess is a company selling online and physical chess equipment and courses.`,
        noclope: `Noclope helps people who are determined not to crack and stay motivated to quit smoking.`,
        ilvesuvio: `Il Vesuvio is an Italian restaurant chain based in Switzerland and France.`
      },
      period: {
        nomorelag: 'Under development',
        gtalents: 'October - November 2020',
        simget: 'June - August 2020',
        clovisechecs: 'July - August 2020',
        noclope: 'Under development',
        ilvesuvio: 'February - March 2020'
      },
      objs: {
        nomorelag: {
          0: 'Auth email/password + Google',
          1: 'Management of presets/assets',
          2: 'Benchmark creation tool',
          3: `Input lag simulator`,
          4: 'Human benchmark test',
          5: 'New features'
        },
        gtalents: {
          0: 'Offer retrieval via CRM API',
          1: 'Offer filters',
          2: `Application request to the API/upload of CVs`
        },
        simget: {
          0: 'Creation of an e-learning platform',
          1: `CRU of the user's progress`,
          2: 'RoadMap',
          3: 'Integration'
        },
        clovisechecs: {
          0: 'Creation of e-commerce website / showcase',
          1: 'Admin store',
          2: 'Admin agenda course booking',
          3: 'Stripes integration'
        },
        noclope: {
          0: 'App front development',
          1: 'Video presentation',
          2: 'Landing page',
          3: 'Metrics admin'
        },
        ilvesuvio: {
          0: 'FB/instagram e-marketing campaign',
          1: '.ch website via WP',
          2: 'Creation of visuals (menu, advertisement)'
        }
      },
      obj: 'goals',
      descri: 'description'
    },
    services: {
      custom: {
        label: 'Specific development',
        desc: {
          0: 'You wish to carry out a very specific project that requires advanced expertise and skills.',
          1: 'I will study with you the realisation of your project and will bring all my expertise to its completion by offering you the right tools for its development.'
        }
      },
      vitrine: {
        label: 'Showcase website',
        desc: {
          0:'You wish to make your digital transition and give your company a presence on the WEB.',
          1:'Whether you want to start from a blank page or with a very specific idea in mind, I will bring my expertise to your project in order to highlight your services and increase your impact.'
        }
      },
      identity: {
        label: 'Corporate Identity',
        desc: {
          0: 'You wish to give your business a visual identity of its own.',
          1: 'I am able to create a detailed graphic charter for you as well as a logo specific to your field of expertise and in line with the times.',
          2: 'This visual identity will allow your company to stand out and increase its notoriety.'
        }
      }
    },
    about: {
      label: 'A word about myself',
      desc: {
        top: 'Freelance for almost a year, I have had the opportunity to perfect my technical and relational skills through different projects. Following a training as a professional developer focused on both computer programming and network administration, I decided to dedicate myself mainly to WEB development, as well as website design and integration.',
        bottom: 'It is nowadays almost essential for a company or a freelancer to benefit from a visibility on the internet in order to expand and make its business flourish. Concerned about this major challenge, I decided to support professionals in their digital transition.'
      }
    },
    contact: {
      label: 'Now, about your project...',
      name: 'What is your full name ?',
      email: 'What is your email adress ?',
      obj: 'Object',
      verif: 'Verification',
      lservices: `What sort of creative work do you need help with ?`,
      message: 'What is your project all about ?',
      send: 'Send',
      robot: 'I am not a robot',
      loading: 'Loading..',
      success: 'Your message has been sent successfully, I will reply as soon as possible. Thank you!',
      error: 'An error has occurred, please try again later.',
      remaining: 'questions remaining',
      services: {
        showcase: `Creation of a showcase site`,
        specs: 'Specific development',
        webdesign: 'Web site model',
        graphic: `Graphic charter`,
        logo: `Creation of a logo`,
        marketing: 'Digital marketing'
      }
    }
  },
  fr: {
    header: {
      quote: 'Demande de devis'
    },
    cover: {
      content: `Développeur et web designer indépendant de 23 ans, je développe une identité numérique et une expérience web unique pour chacun de mes clients.`
    },
    menu: {
      services: 'Services',
      portfolio: 'Portfolio',
      about: 'A propos',
      contact: 'Contact',
      close: 'Fermer'
    },
    project: {
      label: 'Mes réalisations',
      desc: {
        nomorelag: `No more lag est une plateforme ayant pour objectif de rassembler des informations et des outils sur des sujets tels que l'esport l'hardware ou la création de contenu.`,
        gtalents: `G-Talents est un cabinet de chasseurs de têtes, dénicheur de talents Salesforce lyonnais.`,
        simget: `Sim & Get et une startup française spécialisée dans la fusion entre la médecine moderne et l'intelligence artificielle.`,
        clovisechecs: `Clovis échecs est une société de vente de matériel et de cours d'échecs en ligne et physique.`,
        noclope: `Noclope aide les personnes déterminées à ne pas craquer et à rester motivées pour arrêter de fumer.`,
        ilvesuvio: `Il Vesuvio est une chaîne de restauration italienne basée en Suisse et en France.`
      },
      period: {
        nomorelag: 'En développement',
        gtalents: 'Octobre - Novembre 2020',
        simget: 'Juin - Août 2020',
        clovisechecs: 'Juillet - Août 2020',
        noclope: 'En développement',
        ilvesuvio: 'Février - Mars 2020'
      },
      objs: {
        nomorelag: {
          0: 'Auth email/mot de passe + Google',
          1: 'Gestion des presets/assets',
          2: 'Outil de création de benchmark',
          3: `Simulateur d'input lag`,
          4: 'Human benchmark test',
          5: 'Nouvelles fonctionnalités'
        },
        gtalents: {
          0: 'Récupération des offres via API du CRM',
          1: 'Filtres des offres',
          2: `Candidature requête vers l'API/upload de C.V`
        },
        simget: {
          0: 'Création plateforme e-learning',
          1: `CRU de l'avancement du user`,
          2: 'RoadMap',
          3: 'Intégration'
        },
        clovisechecs: {
          0: 'Création site e-commerce/vitrine',
          1: 'Admin store',
          2: 'Admin agenda réservation de cours',
          3: 'Intégration stripes'
        },
        noclope: {
          0: 'Développement App front',
          1: 'Vidéo présentation',
          2: 'Landing page',
          3: 'Metrics admin'
        },
        ilvesuvio: {
          0: 'Campagne e-marketing FB/instagram',
          1: 'Site .ch via WP',
          2: 'Création de visuelles (carte, pub)'
        }
      },
      obj: 'objectifs',
      descri: 'description'
    },
    services: {
      custom: {
        label: 'Développement spécifique',
        desc: {
          0: `Vous souhaitez réaliser un projet bien spécifique qui requiert une expertise et des compétences avancées.`,
          1: `J'étudierai avec vous la réalisation de votre projet et apporterai toute mon expertise à l'aboutissement de ce dernier en vous proposant les bons outils propres à son développement.`
        }
      },
      vitrine: {
        label: 'Site Vitrine',
        desc: {
          0: `Vous souhaitez effectuer votre transition numérique et donner à votre entreprise une présence sur le WEB.`,
          1: `Que vous souhaitiez partir d'une page blanche ou avec une idée bien précise en tête j'apporterai mon expertise à votre projet afin de mettre en valeur vos services et augmenter votre impact.`
        }
      },
      identity: {
        label: 'Identité visuelle',
        desc: {
          0: `Vous souhaitez donner une identité visuelle propre à votre activité.`,
          1: `Je suis en mesure de réaliser pour vous une charte graphique détaillée ainsi qu'un logo propre à votre domaine d'expertise et dans l'air du temps.`,
          2: `Cette identité visuelle permettra à votre entreprise de se démarquer et d'accroître ainsi sa notoriété.`     
        }
      }
    },
    about: {
      label: 'Un mot sur moi',
      desc: {
        top: `Freelance depuis près d'un an, j'ai eu l'occasion de parfaire mes compétences techniques et relationnelles au travers de différents projets. Suite à une formation de développeur professionnalisante orientée tant sur la programmation informatique que l'administration réseau, j'ai décidé de me consacrer principalement au développement WEB, ainsi qu'au design et à l'intégration de site internet.`,
        bottom: `Il est aujourd'hui quasi indispensable pour une société ou un indépendant de bénéficier d'une visibilité sur internet afin d'étendre et faire prospérer son activité. Soucieux de cet enjeu majeur j'ai décidé d'accompagner les professionnels vers leur transition numérique.`
      }
    },
    contact: {
      label: 'Maintenant, à propos de votre projet...',
      name: 'Quel est votre nom ?',
      email: 'Quelle est votre adresse email ?',
      obj: 'Objet',
      verif: 'Vérification',
      lservices: `Pour quel type de travail créatif avez-vous besoin d'aide ?`,
      message: 'En quoi consiste votre projet ?',
      send: 'Envoyer',
      robot: 'Je ne suis pas un robot',
      loading: 'Chargement..',
      success: 'Votre message a bien été envoyé, je vous repondrai dans les plus brefs délais. Merci !',
      error: 'Une erreur est survenue veuillez réessayer ultérieurement.',
      remaining: 'questions restantes',
      services: {
        showcase: `Création d'un site vitrine`,
        specs: 'Développement spécifique',
        webdesign: 'Maquette de site web',
        graphic: `Charte graphique`,
        logo: `Création d'un logo`,
        marketing: 'Marketing digital'
      }
    }
  }
}