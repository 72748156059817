import { createApp } from 'vue'
import App from './App'
import VueScrollTo from 'vue-scrollto'
import { createI18n } from 'vue-i18n'
import { messages } from './common'
import BaseIcon from './components/base/BaseIcon'

const i18n = createI18n({
  locale: 'fr',
  messages
})

const app = createApp(App)

app.use(i18n)
app.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

app.component('BaseIcon', BaseIcon)

app.mount('#app')
