<template>
  <div ref="header" id="header" class="header-top">
    <Menu :show="showMenu" :close="() => {showMenu = false}"></Menu>
    <menu-button @click="() => {showMenu = true}"></menu-button>
    <div id="links-container">
      <button @click="() => $i18n.locale = $i18n.locale  === 'fr' ? 'en' : 'fr'" id="btn-lang">
          {{$i18n.locale  === 'fr' ? 'FR' : 'EN'}}
      </button>
      <button ref="quote" id="btn-quote" class="btn-dark shadow-xl" v-scroll-to="{ el: '#contact' }">
        {{ $t('header.quote') }}
      </button>
    </div>
  </div>
</template>
<script>
import Menu from '@/components/container/menu.vue'
import MenuButton from '@/components/button/menu-button.vue'

export default {
  name: 'Header',
  data: function () {
    return {
      showMenu: false
    }
  },
  components: {
    Menu,
    'menu-button': MenuButton
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('keydown', this.handleKey)
  },
  mounted () {
    this.handleScroll()
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('keydown', this.handleKey)
  },
  methods: {
    handleScroll () {
      if (window.scrollY > 20) {
        this.$refs.header.classList.add('header-scrolled')
        this.$refs.header.classList.remove('header-top')
        this.$refs.quote.classList.remove('shadow-xl')
        this.$refs.quote.classList.add('dark')
      } else {
        this.$refs.header.classList.remove('header-scrolled')
        this.$refs.header.classList.add('header-top')
        this.$refs.quote.classList.add('shadow-xl')
        this.$refs.quote.classList.remove('dark')
      }
    },
    handleKey (event) {
      if (event.key === 'Escape') {
        this.showMenu = false
      }
    }
  }
}
</script>
<style lang="scss">
#logo-header {
  height: 2rem;
  text-align: center;
  margin:auto;
}
#logo-header > * * {
  fill: black;
}
#header {
  position: fixed;
  top: 0px;
  left:0px;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  transition: all .3s ease-in-out;
  #links-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.header-top {
  padding: 40px 80px;
  width: calc(100% - 160px);
}

.header-scrolled {
  background: rgba(255,255,255,.5);
  padding: 10px 60px;
  width: calc(100% - 120px);
}

#btn-lang {
  font-size: 15px;
  border: none;
  background-color: transparent;
  position: relative;
  margin: 0px 20px;
  font-weight: 600;
  transition: .3s all ease-in-out;
}

#btn-lang:hover {
  color: blue;
}

#lang-button:hover {
  #btn-line {
    background-position: left;
  }
  h3 {
    color: blue;
  }
}

@media only screen and (max-width : 1024px) {
  .header-top {
    width: calc(100% - 80px) !important;
    padding: 30px 40px !important;
  }
  #btn-quote {
    display: none;
  }
  #btn-lang {margin: 0px;}
}

@media only screen and (max-width : 800px) {
  .header-top {
    width: calc(100% - 60px) !important;
    padding: 20px 30px !important;
  }
  .header-scrolled {
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
}
</style>
