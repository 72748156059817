<template>
  <div id="main">
    <Header />
    <Cover :loaded="loaded" />
    <div id="grid-tablet" :style="{backgroundImage: 'url('+require('../../assets/images/responsive.jpg')+')'}" class="poster"></div>
    <Services id="services" />
    <Portfolio data-aos="fade-up" id="portfolio" />
    <About data-aos="fade-right" id="about"></About>
    <div :style="{backgroundImage: 'url('+require('../../assets/images/grid.jpg')+')'}" class="poster"></div>
    <Contact @objreset="setObject(false)" :object="obj ?  $t('header.quote') : ''" id="contact" />
  </div>
</template>
<script>
import Header from '@/components/container/header'
import Cover from '@/components/sections/cover.vue'
import Services from '@/components/sections/services.vue'
import Portfolio from '@/components/sections/portfolio.vue'
import About from '@/components/sections/about.vue'
import Contact from '@/components/sections/contact.vue'

export default {
  name: 'Main',
  props: {
    loaded: Boolean
  },
  components: {
    Header,
    Cover,
    Services,
    Portfolio,
    About,
    Contact
  }
}
</script>
<style lang="scss">
#main {
  position: relative;
}

.poster {
  width: 100%;
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
