<template>
  <transition name="menu">
    <div v-if="show" class="menu-container">
      <div style="display: flex;">
        <div style="flex: 1; text-align: left; padding: 1.5rem;"></div>
        <button id="close-menu" @click="close"><strong>{{ $t('menu.close') }}</strong><Close id="close-icon" /></button>
      </div>
      <div class="menu-links">
          <a class="menu-link" v-for="r in routes" :key="r" v-scroll-to="{ el: `#${r}`, onStart: close }">
          <Arrow class="arrow" /><strong>{{ $t(`menu.${r}`) }}</strong>
        </a>
      </div>
      <div class="menu-footer">
        <div style="display: flex;">
          <a class="socials-links" href="https://www.linkedin.com/in/lo%C3%AFc-miralles-5b6a78173/">
            <Linkedin />
          </a>
        </div>
        <div id="mail-menu" style="flex: 1; display: flex; justify-content: flex-end; align-items: center;">
          <div class="divider-circle"></div>
          <a id="mail" href='mailto:contact@loicmiralles.com'>contact@loicmiralles.com</a>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Arrow from '@/components/Icons/Arrow'
import Linkedin from '@/components/Icons/Linkedin'
import Close from '@/components/Icons/Close'

export default {
  name: '',
  data: function () {
    return {
      routes: [
        'services', 'portfolio', 'about', 'contact'
      ]
    }
  },
  components: {
    Arrow,
    Linkedin,
    Close
  },
  props: {
    show: Boolean,
    close: Function
  }
}
</script>
<style lang="scss">
 @import "../../scss/_variables.scss";
.menu-link {
  color: white;
  font-size: 6rem;
  height: 7.5rem;
  display: block;
}
.menu-container {
  position: fixed;
  transform: translate(0, 0) !important;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  max-width: 900px;
  z-index: 3;
  background-color: #00171f;
  .menu-links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: right;
    width: 80%;
  }
  .arrow {
    height: 3.5rem;
    margin-right: 40px;
    fill: transparent;
    transition: .3s all ease-in-out;
    transform: scale(.5);
  }
  a {
    color: white;
    transition: .3s all ease-in-out;
  }
  a:hover {
    color: $menu;
  }
  a:hover > .arrow {
    margin-right: 25px;
    fill: $menu;
    transform: scale(1);
  }
  .menu-footer {
    position: absolute;
    bottom: 0px;
    padding: 2rem 0px;
    width: 100%;
    display: flex;
    align-items: center;
    * {
      padding: 0px 1rem;
    }
  }
}

.divider-circle {
  height: 10px;
  width: 10px;
  padding: 0px !important;
  border-radius: 50%;
  background: white;
}

#menu-btn-lang {
  color: white;
  background: transparent;
  border: none;
  font-size: 1rem;
  padding: 0px !important;
}

#menu-btn-lang:hover {
  color: $menu;
  transition: .3s all ease-in-out;
}

#close-icon {
  fill: white;
  height: 20px;
  margin-left: 15px;
  transition: .3s all ease-in-out;
}

#logo-menu  {
  height: 40px;
  fill: white;
}

#mail {
  text-decoration: none;
  text-align: right;
  height: fit-content !important;
}

#mail > span {
  opacity: .3;
  transition: .3s all ease-in-out;
  color: white !important;
}

#mail:hover > span {
  opacity: 1;
}

.menu-leave-active,
.menu-enter-active {
  transition: .4s;
}
.menu-enter-from {
  margin-left: -100vw;
  opacity: 0;
}
.menu-leave-to {
  margin-left: -100vw;
  opacity: 0;
}

#close-menu {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  transition: .3s all ease;
  margin: 1.5rem;
  flex: none;
  display: flex;
  align-items: center;
}

#close-menu:hover {
  color: rgb(246, 59, 59);
}

#close-menu:hover > #close-icon {
  fill:rgb(246, 59, 59);
}

@media only screen and (max-width : 800px) and (min-width: 600px) {
  .menu-link {
    font-size: 4rem;
    height: 5.5rem;
  }
}

@media only screen and (max-width : 800px) {
  .menu-link {
    text-align: center !important;
  }
  .arrow {
    display: none;
  }
  #close-menu {
    font-size: 1.2rem !important;
  }
  #close-icon {
    height: 15px;
    margin-left: 10px;
  }
}

@media only screen and (max-width : 600px) {
  .menu-link {
    font-size: 2rem;
    height: 3.5rem;
  }
  #mail-menu {
    display: none !important;
  }
}

@media only screen and (max-width : 1250px) and (min-width: 800px) {
  .menu-link {
    font-size: 5rem;
    height: 6rem;
  }
}

.socials-links {
  display: flex;
  align-items: center;
  padding-right: .25rem !important;
  svg {
    padding: 0px !important;
    height: 1.4rem;
    fill: white;
    transition: .3s all ease-in-out;
  }
}

.socials-links:hover > svg {
  fill: $menu;
}
</style>
